<template>
  <b-table
    @sort-changed="sortChanged"
    :fields="fields"
    :items="items"
    :busy="busy"
    :stickyHeader="false"
    :responsive="true"
    :bordered="bordered"
    :borderless="borderless"
    :striped="striped"
    :outlined="outlined"
    :small="small"
    :hover="hover"
    :dark="dark"
    :fixed="fixed"
    :footClone="footClone"
    :noBorderCollapse="noCollapse"
    :empty-text="$t('general.noRecord')"
    :empty-filtered-text="$t('general.noRecord')"
    :no-local-sorting="true"
    show-empty
  >
    <template #cell(increasement)="data">
      {{ calculateOrderNumber(data.index) }}
    </template>
    <template #cell(createdAt)="data">
      {{ data.item.createdAt | formatDate }}
    </template>
    <!-- <template #table-busy>
      <div class="text-center my-1">
        <b-skeleton-table
          :rows="10"
          :columns="fields.length"
          :hide-header="true"
          :table-props="{ bordered: false, striped: true }"
        ></b-skeleton-table>
      </div>
    </template> -->
    <template #table-busy>
      <div class="text-center my-1">
        <b-spinner label="Spinning"></b-spinner>
      </div>
    </template>

    <template #head()="scope">
      <div class="text-nowrap">{{ $t(scope.label) }}</div>
    </template>

    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </b-table>
</template>

<script>
import { BTable, BSkeletonTable, BSpinner } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BTable,
    BSkeletonTable,
    BSpinner,
  },
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    fields: {
      type: Array,
      default: function () {
        return [];
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    busy: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: function () {
        return this.$store.state.pagination.perPage;
      },
    },
  },
  methods: {
    calculateOrderNumber(index) {
      const from = (this.currentPage - 1) * this.perPage + 1;
      return from + index;
    },
    submit() {
      this.$emit("submit");
    },
    sortChanged(ctx) {
      const item = this.fields.find((value) => {
        if (value.key === ctx.sortBy) {
          return value;
        }
      });

      if (!item) {
        return;
      }

      const sortBy = item?.sortField ? item.sortField : item?.key;
      const sortDirection = ctx.sortDesc ? "desc" : "asc";
      this.$emit("sort-changed", sortBy, sortDirection);
    },
  },
  setup() {
    // App Name
    const {
      bordered,
      borderless,
      striped,
      outlined,
      small,
      hover,
      dark,
      fixed,
      footClone,
      noCollapse,
    } = $themeConfig.table;
    return {
      bordered,
      borderless,
      striped,
      outlined,
      small,
      hover,
      dark,
      fixed,
      footClone,
      noCollapse,
    };
  },
};
</script>

<style scoped>
</style>
